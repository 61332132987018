import axios from 'axios';
import { toast } from 'vue3-toastify';

import authInterceptor from './auth.interceptor';
import refreshInterceptor from './refresh.interceptor';
import reCaptchaInterceptor from "@/interceptors/recaptcha.interceptor";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        // Check if the error response exists and has data
        if (error?.response?.data) {
            // Display the error message from the server in Polish
            toast.error(error.response.data.detail || 'Wystąpił błąd');
        } else {
            // Display a generic error message
            toast.error('Wystąpił nieoczekiwany błąd');
        }

        // Optionally, return a rejected promise to propagate the error
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.request.use(reCaptchaInterceptor);
axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use((response) => response, refreshInterceptor);

export default axiosInstance;
