import { useAuthStore } from '@/stores/auth';
import axiosInstance from '@/interceptors/axios.config';

export default async function refreshInterceptor(error) {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const authStore = useAuthStore();
        await authStore.refreshAccessToken();

        return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
}
