<template>
  <v-dialog class="dialog" v-model="authStore.showTermsModal" persistent max-width="500">
    <v-card class="terms-acceptance-dialog-content">
      <v-card-title class="headline">Akceptacja Regulaminu</v-card-title>
      <v-card-text>
        Aby kontynuować, musisz zaakceptować nasz najnowszy
        <a href="#" @click.prevent="viewTerms" class="terms-link">Regulamin</a>
      </v-card-text>
      <v-divider class="divider"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="buttons-wrapper">
          <v-btn color="primary" @click="acceptTerms">Akceptuję</v-btn>
          <v-btn color="error" @click="rejectTerms">Odrzucam</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import axiosInstance from "@/interceptors/axios.config";
import {toast} from "vue3-toastify";

export default {
  name: 'SessionTimeoutDialog',
  setup() {
    const authStore = useAuthStore();

    return { authStore };
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
    }
  },
  methods: {
    async acceptTerms() {
      try {
        const authStore = useAuthStore();

        // Call API to accept terms
        await axiosInstance.post(this.apiUrl + 'update-terms-acceptance/', {
          latest_terms_accepted: true
        });

        // Update the user data in the auth store if needed
        authStore.hasAcceptedLatestTerms = true;
        authStore.showTermsModal = false;

        // Navigate to the dashboard
        this.$router.push({ name: 'dashboard' });
      } catch (error) {
        console.error('Error accepting terms:', error);
      }
    },
    viewTerms() {
      // Open terms of service in a new tab
      window.open(this.$router.resolve({ name: 'terms-of-service' }).href, '_blank');
    },
    rejectTerms() {
      // Close the modal and show an error message
      toast.warning('Musisz zaakceptować regulamin, aby się zalogować.')
      // Clear the login data
      this.username = '';
      this.password = '';
    },
  }
};
</script>

<style scoped lang="scss">
.terms-acceptance-dialog-content {
  max-width: calc(100vw - 4rem);
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;

  @media (min-width: 768px) {
    max-width: 600px;
    padding: 20px;
  }
}

.headline {
  font-weight: 600;
}

.divider {
  margin: 8px 0 4px;
}

.terms-link {
  color: #1976d2;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  font-weight: 500;

  &:hover {
    padding-bottom: 2px;
    border-bottom: 1px solid #1976d2;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}

.dialog-button {
  display: flex;
  padding: 0 16px;
  text-transform: none !important;
  box-shadow: 0 0 4px 1px rgba(44, 44, 44, 0.25);
}
</style>