<template>
  <v-dialog class="dialog" v-model="authStore.showLogoutDialog" persistent>
    <v-card class="countdown-dialog">
      <v-card-title class="headline">Twoja sesja wygasa</v-card-title>

      <v-card-text>
        Zostaniesz za chwilę wylogowany. Potrzebujesz więcej czasu?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <div class="buttons-wrapper">
          <v-btn class="dialog-button" color="primary" @click="authStore.prolongSession">Przedłuż sesję</v-btn>

          <v-btn class="dialog-button" color="error" @click="authStore.logout">Wyloguj mnie</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useAuthStore } from '@/stores/auth';

export default {
  name: 'SessionTimeoutDialog',
  setup() {
    const authStore = useAuthStore();

    return { authStore };
  },
};
</script>

<style scoped>
.headline {
  font-weight: 600;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
}

.dialog-button {
  display: flex;
  padding: 0 16px;
  text-transform: none !important;
  box-shadow: 0 0 4px 1px rgba(44, 44, 44, 0.25);
}

.countdown-dialog {
  max-width: calc(100vw - 4rem);
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;

  @media (min-width: 768px) {
    max-width: 600px;
    padding: 20px;
  }
}
</style>
